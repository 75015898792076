import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'activate-account/:token',
    loadChildren: () => import('./pages/activate-account/activate-account.module').then(m => m.ActivateAccountModule)
  },
  {
    path: 'upload-edit',
    loadChildren: () => import('./pages/upload-edit/upload-edit.module').then(m => m.UploadEditModule)
  },
  {
    path: 'upload-edit/:video_id',
    loadChildren: () => import('./pages/upload-edit/upload-edit.module').then(m => m.UploadEditModule)
  },
  {
    path: 'upload-video',
    loadChildren: () => import('./pages/upload-video/upload-video.module').then(m => m.UploadVideoModule)
  },
  {
    path: 'video/:video_id',
    loadChildren: () => import('./pages/single-video/single-video.module').then(m => m.SingleVideoModule)
  },
  {
    path: 'channels',
    loadChildren: () => import('./pages/channel/channel.module').then(m => m.ChannelModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/browse-categories/browse-categories.module').then(m => m.BrowseCategoriesModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryModule)
  },
  {
    path: 'live',
    loadChildren: () => import('./pages/live-video/live-video.module').then(m => m.LiveVideoModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'login/:page_redirection',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'search-video',
    loadChildren: () => import('./pages/search-video/search-video.module').then(m => m.SearchVideoModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./pages/updates-from-subs/updates-from-subs.module').then(m => m.UpdatesFromSubsModule)
  },
  {
    path: 'pro-account',
    loadChildren: () => import('./pages/pro-account/pro-account.module').then(m => m.ProAccountModule)
  },
  {
    path: 'user-account',
    loadChildren: ()=>import('./pages/user-account/user-account.module').then((model)=>model.UserAccountModule)
  },
  {
    path: 'watch-later',
    loadChildren: () => import('./pages/watch-later/watch-later.module').then(m => m.WatchLaterModule)
  },
  {
    path: 'liked-video',
    loadChildren: () => import('./pages/liked-video/liked-video.module').then(m => m.LikedVideoModule)
  },
  {
    path: 'playlist/:playlist_id',
    loadChildren: () => import('./pages/playlist-video/playlist-video.module').then(m => m.PlaylistVideoModule)
  },
  {
    path: 'playlist/:playlist_id/:video_id',
    loadChildren: () => import('./pages/playlist-video/playlist-video.module').then(m => m.PlaylistVideoModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryModule)
  },
  {
    path: 'trending',
    loadChildren: () => import('./pages/trending/trending.module').then(m => m.TrendingModule)
  },
  {
    path: 'popular',
    loadChildren: () => import('./pages/popular/popular.module').then(m => m.PopularModule)
  },
  {
    path: 'movie',
    loadChildren: () => import('./pages/movie/movie.module').then(m => m.MovieModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'payment/:video_source_id',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'payment/channel/:channel_id',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'payment/pro_account/:pro_account_type',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'payment/:pro_account_gift/:pro_account_type',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'payment/channel/:channel_id/:membership_type',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'payment/live/:live_id',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'payment/:video_source_id/:donation',
    loadChildren: () => import('./pages//payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'payment/:live_video_id/:live_donation/:is_live_param',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule)
  },
  { path: 'page', loadChildren: () => import('./pages/static/static.module').then(m => m.StaticModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
