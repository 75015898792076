<section class="form_popup">
  <div class="signup_form.show" id="signup_form" style="overflow-y: auto;"> <!-- free to join sign up modal won't display without class .show for signup_form -->
    <div class="hd-lg">
      <h2>
        <span>Signup</span>
        <a style="position: absolute; left: 92%;" (click)="hideSignUpModal()" title="">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM8.70711 16.7071L12 13.4142L15.2929 16.7071L16.7071 15.2929L13.4142 12L16.7071 8.70711L15.2929 7.29289L12 10.5858L8.70711 7.29289L7.29289 8.70711L10.5858 12L7.29289 15.2929L8.70711 16.7071Z" fill="#9494A0"/>
          </svg>
        </a>
      </h2>
    </div>
    <!--hd-lg end-->
    <!-- removed for now as Eldad correction -->
    <!-- <div class="lg-in">
      <ul>
        <li class="link">
          <a (click)="loginFacebook()" title="" class="facebook">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 3.33356C20 1.582 18.4187 0 16.6667 0H3.33333C1.58133 0 0 1.582 0 3.33356V16.6664C0 18.418 1.58133 20 3.33356 20H10V12.4444H7.55556V9.11111H10V7.81244C10 5.57267 11.6818 3.55556 13.75 3.55556H16.4444V6.88889H13.75C13.4551 6.88889 13.1111 7.24689 13.1111 7.78311V9.11111H16.4444V12.4444H13.1111V20H16.6667C18.4187 20 20 18.418 20 16.6664V3.33356Z"
                fill="white" />
            </svg> Facebook
          </a>
        </li>
        <li class="link">
          <a (click)="loginTwitter()" title="" class="twitter">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V2C20 0.9 19.1 0 18 0ZM15.7 7.3C15.6 11.9 12.7 15.1 8.3 15.3C6.5 15.4 5.2 14.8 4 14.1C5.3 14.3 7 13.8 7.9 13C6.6 12.9 5.8 12.2 5.4 11.1C5.8 11.2 6.2 11.1 6.5 11.1C5.3 10.7 4.5 10 4.4 8.4C4.7 8.6 5.1 8.7 5.5 8.7C4.6 8.2 4 6.3 4.7 5.1C6 6.5 7.6 7.7 10.2 7.9C9.5 5.1 13.3 3.6 14.8 5.5C15.5 5.4 16 5.1 16.5 4.9C16.3 5.6 15.9 6 15.4 6.4C15.9 6.3 16.4 6.2 16.8 6C16.7 6.5 16.2 6.9 15.7 7.3Z"
                fill="white" />
            </svg>
            Twitter
          </a>
        </li>
      </ul>
      <span>or</span>
    </div> -->
    <!--lg-in end-->
    <div *ngIf="sign_up_success == false || sign_up_success == undefined" class="user-account-pr">
      <!-- <h4>Signup with email to continue with</h4> -->
      <img src="assets/images/icon3.png" alt="" style="margin: 1em 0">
      <form [formGroup]="registerForm" style="padding-top: 0 !important;" (ngSubmit)="onSubmit()">
        <div class="input-sec">
          <input type="text"  placeholder="name" formControlName="full_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.full_name.errors }" />
          <span class="field_shp">
            <img src="assets/images/vc1.png" alt="">
          </span>
          <div *ngIf="submitted && f.full_name.errors" class="invalid-feedback">
            <div *ngIf="f.full_name.errors.required">Full name is required</div>
          </div>
        </div>
        <div class="input-sec">
          <input type="email"  placeholder="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <span class="field_shp">
            <img src="assets/images/vc3.png" alt="">
          </span>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>
        <!-- <div class="input-sec mg_25">
          <input type="text" formControlName="username" class="form-control" placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <span class="field_shp">
            <img src="assets/images/vc1.png" alt="">
          </span>
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
            <div *ngIf="f.username.errors.pattern">Letters A-Z or a-z, Numbers 0-9 and Underscores_</div>
          </div>
        </div> -->
        <div class="input-sec">
          <input type="password" formControlName="password" class="form-control" placeholder="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <span class="field_shp">
            <img src="assets/images/vc2.png" alt="">
          </span>
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback text-danger">
            <p *ngIf="f.password.errors.required">Password is required</p>
            <p *ngIf="f.password.errors.minlength">Password must be at least 6 characters</p>
          </div>
        </div>

        <div class="input-sec">
          <input type="password" formControlName="confirm_password" class="form-control" placeholder="confirm password" [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" />
          <span class="field_shp">
            <img src="assets/images/vc2.png" alt="">
          </span>
          <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback text-danger">
            <div *ngIf="f.confirm_password.errors.required">Confirm Password is required</div>
            <div *ngIf="f.confirm_password.errors.mustMatch">Passwords must match</div>
          </div>
        </div>

        <div class="text-danger" style="margin-bottom: 20px;">
          <div *ngIf="email_already_exist == true">Email already exist!</div>
          <!-- <div *ngIf="username_already_exist == true">Username already exist!</div> -->
          <div *ngIf="user_already_exist == true">Email already exist!</div>
          <div *ngIf="sending_email_error == true">
            There an unexpected error while sending mail!
            Please, make sur that your email is correct and exist!
          </div>
        </div>

        <div class="input-sec mb-0">
          <!-- <button type="submit">Sign Up</button> -->
          <button [disabled]="loading" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Sign Up
          </button>
        </div>
        <!--input-sec end-->
      </form>
      <h4 style="margin-bottom: 0px !important">By signing up you agree to ProjectFitnessTV’s
        <a href="/page/terms_of_sale">Terms of Service</a> and
        <a href="/page/privacy_notice"> Privacy Policy</a>
      </h4>
    </div>
    <!--user-account end-->
    <div *ngIf="sign_up_success == false || sign_up_success == undefined" class="fr-ps">
      <!-- <h1>Already an account? <a routerLink="login" title="" class="login_form_show">Login here.</a></h1> -->
      <h1>Already have an account? <a routerLink="/login">Login here.</a></h1>
    </div>
    <!--fr-ps end-->

    <!-- After sign up success -->
    <div *ngIf="sign_up_success == true" class="user-account-pr">
      <br/>
      <br/>
      <img src="assets/images/icon3.png" alt="">
      <br/>
      <br/>
      <br/>
      <h4>We have send a verification mail to:
        <br/>
          <a> {{ email_for_sign_up }} </a>
        <br/>
        Please, verify your mail to finish the sign up process!
        <br/>
        <br/>
        ( If you do not see a verification email in your inbox, please check spam fold. )
      </h4>
      <br/>
    </div>
    <!-- sign up success end -->

  </div>
  <!--login end--->
</section>
